import React from "react"

const Additional = ({ title, children, className, icon }) => {
  return (
    <div className={className}>
      <div className="icon-container">
        <img className="icon" src={icon} alt={title} />
      </div>
      <h3>{title}</h3>
      {children}
    </div>
  )
}

export default Additional
