import React from "react";

import Button from "./common/button";

const Footer = ({ onButtonClick }) => {
    return (
        <footer id="footer">
            <div className="container">
                <Button
                    className="xxl"
                    label="Obtener Cotización"
                    description="O llámenos al 6009-8104"
                    icon="fa-arrow-up"
                    onClick={onButtonClick}
                />
                <div className="row">
                    <div className="col">
                        <p>
                            Copyright &copy; 2021 - Todos los derechos
                            reservados
                        </p>
                        <ul className="list-inline">
                            <li className="list-inline-item"><a href="/privacy" target="_blank"><u>Privacy Policy</u></a></li>
                        </ul>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.facebook.com/CateringServiceRio/"
                                >
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.instagram.com/catering_service_rio/"
                                >
                                    <i className="fa fa-instagram"></i>
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://wa.me/50660098104"
                                >
                                    <i className="fa fa-whatsapp"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
