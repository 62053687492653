import React from "react";
import PropTypes from "prop-types";

const Button = ({
    label,
    description,
    className,
    icon,
    onClick,
    submiting,
}) => {
    let classes = ["btn btn-primary"];
    if (submiting) classes.push("disabled");

    return (
        <div className={`button ${className}`}>
            <a
                href="/"
                onClick={e => {
                    e.preventDefault();
                    onClick();
                }}
                className={classes.join(" ")}
            >
                {label}
                {icon ? <i className={`fa ${icon}`} /> : ""}
            </a>
            <p
                className="desc"
                dangerouslySetInnerHTML={{ __html: description }}
            ></p>
        </div>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    description: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.func,
};

Button.defaultProps = {
    className: "",
    icon: "fa-arrow-right",
};

export default Button;
