import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

import logo from "../images/catering-service-rio-logo.png";

const Header = ({ siteTitle }) => (
    <header id="header">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-5">
                    <h1>
                        <Link to="/">
                            <img src={logo} alt={siteTitle} />
                        </Link>
                    </h1>
                </div>
                <div className="col-7 text-right">
                    <ul className="list-inline">
                        <li className="list-inline-item call d-none d-sm-inline-block">
                            Llame Ahora:{" "}
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="tel:60098104"
                                className="phone"
                            >
                                6009-8104
                            </a>
                        </li>
                        <li className="list-inline-item spacer d-none d-sm-inline-block"></li>
                        <li className="list-inline-item d-inline-block d-sm-none">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="tel:+50660098104"
                            >
                                <i className="fa fa-phone"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.facebook.com/CateringServiceRio/"
                            >
                                <i className="fa fa-facebook"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.instagram.com/catering_service_rio/"
                            >
                                <i className="fa fa-instagram"></i>
                            </a>
                        </li>
                        <li className="list-inline-item">
                            <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://wa.me/50660098104"
                            >
                                <i className="fa fa-whatsapp"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string,
};

Header.defaultProps = {
    siteTitle: ``,
};

export default Header;
