import React from "react";

import Service from "./service";

import GrillImage from "../images/servicio-parrilladas.jpg";
import CSImage from "../images/servicio-catering-service.jpg";

const Services = () => {
    const GrillImg = () => (
        <img
            className="img-fluid"
            src={GrillImage}
            alt="Parrillero Costa Rica - Los mejores cortes"
        />
    );
    const CSImg = () => (
        <img
            className="img-fluid"
            src={CSImage}
            alt="Catering Service Costa Rica - Experiencia y Excelencia"
        />
    );
    return (
        <section id="services" className="section">
            <div className="container">
                <Service
                    title="Parrilladas y Asados"
                    subtitle="Los mejores cortes"
                    image={<GrillImg />}
                >
                    <p>
                        Trate a sus invitados como ellos lo merecen con una deliciosa 
                        parrillada, asado o chicharronada, seleccione dentro de una increible 
                        variedad de las mejores carnes, salsas, hierbas y verduras. 
                        Nuestros parrilleros profesionales se encargarán de hacer de su 
                        actividad toda una experiencia culinaria sin preocupaciones.
                    </p>
                </Service>
                <Service
                    title="Catering Service"
                    subtitle="Experiencia y Excelencia"
                    image={<CSImg />}
                    reverse={true}
                >
                    <p>
                        Sea una boda, fiesta o actividad empresarial, nuestro equipo de catering service
                        entregará una experiencia culinaria sin igual para sus invitados, con los más altos
                        estándares de etiqueta, clase y el mejor sabor de las comidas tradicionales, su evento
                        será memorable. Degustaciones y asesoría de nuestros expertos para asegurar que su
                        actividad sea un exito rotundo.
                    </p>
                </Service>
            </div>
        </section>
    );
};

export default Services;
