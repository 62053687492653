import React from "react";

const Service = ({ title, subtitle, children, image, reverse }) => {
    return (
        <div
            className={
                "row align-items-center" + (reverse ? " flex-row-reverse" : "")
            }
        >
            <div className="col-md-5 col-lg-6">{image}</div>
            <div className="col-md-6 col-lg-5 mx-auto">
                <h4>{subtitle}</h4>
                <h2>{title}</h2>
                {children}
            </div>
        </div>
    );
};

export default Service;
