import React from "react";
import Slider from "react-slick";

import TestimonialImage from "../images/testimonios.jpg";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonials = () => {
    var sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        arrows: false,
        adaptiveHeight: true,
    };
    return (
        <section id="testimonials" className="section">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5 col-lg-6">
                        <img
                            src={TestimonialImage}
                            alt="Catering Service - Clientes Felices"
                            className="img-fluid"
                        />
                    </div>
                    <div className="col-md-6 col-lg-5 mx-auto">
                        <h4>Testimonios</h4>
                        <h2>Lo que dicen nuestros clientes</h2>
                        <Slider {...sliderSettings}>
                            <blockquote>
                                <p>
                                    Muy bueno el servicio que brinda Catering Service RIO. 
                                    Son bastante responsables! Yo quedé sumamente satisfecha!
                                </p>
                                <p><b>⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️<br/>Hazel Cruz</b></p>
                            </blockquote>
                            <blockquote>
                                <p>
                                    El mejor catering..todo excelente la atención de Don Fran, Jose y los saloneros, la cena exquisita igual que la entrada. Los felicito... los recomiendo al 1000%
                                </p>
                                <p><b>⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️<br/>Gabriela Rojas</b></p>
                            </blockquote>
                            <blockquote>
                                <p>
                                    Excelente atención, comida insuperable y sin duda son el mejor servicio de parrillada que se puede contratar ! Agradecido con Jose Mario por su profesionalismo, trato al cliente, y quien con su equipo de colaboradores hacen de los eventos una experiencia única . Los recomiendo
                                </p>
                                <p><b>⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️&nbsp;&nbsp;⭐️<br/>Federico Castillo</b></p>
                            </blockquote>
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Testimonials;
