import React from "react";

import bnLogo from "../images/empresa-bp.png";
import fifcoLogo from "../images/empresa-fifco.png";
import unedLogo from "../images/empresa-uned.png";
import utnLogo from "../images/empresa-utn.png";
import phizerLogo from "../images/empresa-phizer.png";
import durmanLogo from "../images/empresa-durman.png";

const Clients = () => {
    const empresas = [
        {
            name: "Cervecería Costa Rica",
            logo: fifcoLogo,
        },
        {
            name: "UNED",
            logo: unedLogo,
        },
        {
            name: "Banco Popular",
            logo: bnLogo,
        },
        {
            name: "UTN",
            logo: utnLogo,
        },
        {
            name: "Phizer",
            logo: phizerLogo,
        },
        {
            name: "Durman",
            logo: durmanLogo,
        },
    ];

    const logos = empresas.map((empresa, index) => (
        <img key={index} src={empresa.logo} alt={empresa.name} />
    ));

    return (
        <section id="clients">
            <div className="container">
                <div className="d-flex justify-content-between">{logos}</div>
            </div>
        </section>
    );
};

export default Clients;
