import React from "react";

import Additional from "./additional";

import mIcon from "../images/adicional-manteleria.png";
import sIcon from "../images/adicional-sillas.png";
import aIcon from "../images/adicional-animacion.png";
import dIcon from "../images/adicional-decoracion.png";
import uIcon from "../images/adicional-ubicacion.png";

const Additionals = () => {
    const services = [
        {
            title: "Mantelería",
            description:
                "Variedad de cubre manteles, Cubre sillas polyester, manteles rectangular blanco, negros o cualquier color",
            icon: mIcon,
        },
        {
            title: "Sillas y Mesas",
            description:
                "Alquiler de sillas plegables, plásticas, mesas plegables rectangulares, redondas y cocteleras",
            icon: sIcon,
        },
        {
            title: "Animación",
            description:
                "Animación y shows en vivo para todo tipo de eventos",
            icon: aIcon,
        },
        {
            title: "Decoración",
            description:
                "Centros de mesa, Decoración de iglesias, Salones, al aire libre, decoración con telas",
            icon: dIcon,
        },
        {
            title: "Ubicación",
            description:
                "Excelentes ubicaciones, Con todo lo necesario para un evento a la altura de sus necesidades",
            icon: uIcon,
        },
    ];

    const adicionales = services.map((service, index) => (
        <Additional
            className="col-6 col-lg-4 text-center additional"
            title={service.title}
            icon={service.icon}
            key={index}
        >
            <p>{service.description}</p>
        </Additional>
    ));

    return (
        <section id="additionals" className="section">
            <div className="container">
                <h4>Para complementar su experiencia</h4>
                <h2>Servicios Adicionales</h2>
                <div className="row justify-content-center">{adicionales}</div>
            </div>
        </section>
    );
};

export default Additionals;
